import React, { useState } from "react";
import "./register.css";

const Register = () => {
  // eslint-disable-next-line
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [password_verif, setPassword_verif] = useState("");
  const [first_name, setFirstname] = useState("");
  const [last_name, setLastname] = useState("");
  const [phone, setPhone] = useState("");
  const [passwordConditions, setPasswordConditions] = useState({
    length: false,
    uppercase: false,
    number: false,
    special: false
  });

  const changePhone = (event) => {
    setPhone(event.target.value);
  };
  const changeLastname = (event) => {
    setLastname(event.target.value);
  };
  const changeFirstname = (event) => {
    setFirstname(event.target.value);
  };
  const changeEmail = (event) => {
    setEmail(event.target.value);
  };
  const changePassword = (event) => {
    const newPassword = event.target.value;
    setPassword(newPassword);
    
    // Vérification des conditions
    setPasswordConditions({
      length: newPassword.length >= 6,
      uppercase: /[A-Z]/.test(newPassword),
      number: /[0-9]/.test(newPassword),
      special: /[!@#$%^&*_=+-]/.test(newPassword)
    });
  };
  const changePassword_verif = (event) => {
    setPassword_verif(event.target.value);
  };

  const verifCreds = async () => {
    if (password !== password_verif) {
      alert("Les mots de passe ne correspondent pas.");
      return;
    }
    if (
      !password.match(
        /^(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*_=+-])(?=.*[a-zA-Z]).{6,}$/
      )
    ) {
      alert(
        "Le mot de passe doit contenir au moins 6 caractères, une majuscule, un chiffre et un caractère spécial."
      );
      return;
    }
    // eslint-disable-next-line
    if (!email.match(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g)) {
      alert("Mauvais format d'adresse email");
      return;
    }
    registerRequest();
  };

  const registerRequest = async () => {
    let result = await fetch("https://mono.coverage-eip.com/auth/register", {
      method: "POST",
      body: JSON.stringify({
        firstName: first_name,
        email: email,
        lastName: last_name,
        password: password,
        phoneNumber: phone,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
    console.log(result);
    if (result.status === 200) {
      result = result.json().then((data) => {
        if (result.token)
          localStorage.setItem("token", data.token);
        localStorage.setItem("email", email);
        localStorage.setItem("needTutorial", true);
      });
      window.location = "/otp";
    } else {
      result = await result.json();
      localStorage.setItem("token", null);
      alert(result.msg);
      return;
    }
  };

  return (
    <div id="registerPage">
      <div id="registerContainer">
        <div id="form-register-container">
          <div id="title-register-container">Créer un compte</div>
          <div id="subtitle-register-container">
            Saisissez vos informations pour créer votre compte Coverage
          </div>
        <div id="form-register-inputs-container">
            <div id="form-register-inputs-right">
              <input
                type="text"
                placeholder="Nom"
                className="inputs-register"
                value={last_name}
                onChange={changeLastname}
              />
              <input
                type="text"
                placeholder="Prénom"
                className="inputs-register"
                value={first_name}
                onChange={changeFirstname}
              />
              <input
                type="tel"
                placeholder="Numéros de téléphone"
                className="inputs-register"
                value={phone}
                onChange={(e) => {
                  const value = e.target.value;
                  const regex = /^[0-9-\s()]*$/;
                  if (regex.test(value)) {
                    changePhone(e);
                  }
                }}
              />
            </div>
            <div id="form-register-inputs-left">
              <input
                type="text"
                placeholder="Addresse email"
                className="inputs-register"
                value={email}
                onChange={changeEmail}
              />
              <input
                type="password"
                placeholder="Mot de passe"
                className="inputs-register"
                value={password}
                onChange={changePassword}
              />
              <input
                type="password"
                placeholder="Confirmation mot de passe"
                className="inputs-register"
                value={password_verif}
                onChange={changePassword_verif}
              />
              <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'left', width: '100%' }}>
                <div className="password-conditions" style={{ fontSize: '0.8rem', display: 'flex', flexDirection: 'column', textAlign: 'left' }}>
                  <span style={{ color: passwordConditions.length ? 'green' : 'red' }}>
                    ✓ Au moins 6 caractères
                  </span>
                  <span style={{ color: passwordConditions.uppercase ? 'green' : 'red' }}>
                    ✓ Au moins une majuscule
                  </span>
                  <span style={{ color: passwordConditions.number ? 'green' : 'red' }}>
                    ✓ Au moins un chiffre
                  </span>
                  <span style={{ color: passwordConditions.special ? 'green' : 'red' }}>
                    ✓ Au moins un caractère spécial (!@#$%^&*_=+-)
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div id="container-buttons-register">
            <button
              id="back-button-register"
              onClick={() => (window.location = "/Login")}
            >
              Retour
            </button>
            <button id="connect-button-register" onClick={verifCreds}>
              C'est partis
            </button>
          </div>
        </div>
        <div id="register-img-container">
          <img
            src="mockup-register.jpg"
            alt="mockup-register-pic"
            id="mockup-register"
          />
        </div>
      </div>
    </div>
  );
};

export default Register;
