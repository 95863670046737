import React, { useState } from "react";
import "./otp.css";

const Otp = () => {
  const [otp, setOtp] = useState("");

  function changeOtp(event) {
    setOtp(event.target.value);
  }

  async function sendOtpMail() {
    await fetch("https://mono.coverage-eip.com/auth/send-otp-mail", {
      method: "POST",
      body: JSON.stringify({
        email: localStorage.getItem("email"),
        provider: "local"
      }),
      headers: {
        "Content-Type": "application/json",
      },
    }).then((data) => {
        console.log(data.json());
        alert(data.msg);
    });
  }

  async function checkOtp() {
    let result = await fetch("https://mono.coverage-eip.com/auth/verify-otp", {
          method: "POST",
          body: JSON.stringify({
            otp: otp,
            email: localStorage.getItem("email"),
            provider: "local"
          }),
          headers: {
            "Content-Type": "application/json",
          },
        });
        console.log(result);
        console.log(otp, localStorage.getItem("email"));
        if (result.status === 200) {
            result = await result.json().then((data) => {
                localStorage.setItem("token", data.token);
                localStorage.setItem("needTutorial", true);
                window.location = "/Home";
            });
        } else {
            result = await result.json();
            console.log(result);
            alert(result.msg);
            return;
        }
    }

  return (
    <div id="otpPage">
      <div id="otpContainer">
      <div id="form-otp-container">
        <div id="title-otp-container">Code de vérification</div>
        <div id="form-otp-inputs-container">
            <div id="form-otp-inputs-left">
              <input
                type="numbers"
                placeholder="Code"
                className="inputs-otp"
                value={otp}
                onChange={changeOtp}
              />
            </div>
          </div>
          <div id="container-buttons-otp">
            <button id="connect-button-register" onClick={checkOtp}>
              Confirmer
            </button>
          </div>
          <div>
            <button onClick={sendOtpMail}>
              Renvoyer le code
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Otp;
