import React, { useState, useEffect } from "react";
import "./ContratImportAndDisplay.css";
import ContractDisplay from "../../component/ContractDisplay/ContractDisplay";
import UniversalModal from "../UniversalModal/UniversalModal";

const ContratImportAndDisplay = ({ seeContract, actualDomicileId, local }) => {
  const [dragging, setDragging] = useState(false);
  const [files, setFiles] = useState([]);
  const [insurancesList, setInsurancesList] = useState([]);
  const [seeDetails, setSeeDetails] = useState(false);
  const [selectedContract, setSelectedContract] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleDragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(false);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();

    setDragging(false);

    const droppedFiles = [...e.dataTransfer.files];
    setFiles(droppedFiles);
  };

  useEffect(() => {
    getInsurances();
    // eslint-disable-next-line
  }, []);

  const handleDownload = (url) => {
    if (url) {
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "true");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      alert("URL non spécifiée pour le téléchargement.");
    }
  };

  const handleDelete = (id) => {
    const confirmDelete = window.confirm(
      "Voulez-vous vraiment supprimer ce contrat?"
    );
    if (confirmDelete) {
      deleteInsurance(id);
    }
  };

  const deleteInsurance = async (id) => {
    const response = await fetch(
      `https://api.coverage-eip.com/contracts/full/${id}`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );

    if (response.ok) {
      alert("Contrat supprimé avec succès.");
      window.location.reload();
    } else {
      alert(`Échec de la suppression du contrat : ${response.status}`);
      //window.location.reload();
    }
  };

  const showDetails = (contract) => {
    setSelectedContract(contract);
    setSeeDetails(true);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setSeeDetails(false);
    setIsModalOpen(false);
    setSelectedContract(null);
  };

  const getInsurances = async () => {
    let result = await fetch(
      "https://api.coverage-eip.com/contracts/allContracts",
      {
        method: "get",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }
    );

    if (result.status === 200) {
      result.json().then((data) => {
        const insurances = data.map((item) => ({
          id: item.id,
          domicileId: item.domicileId,
          //     historyId: item.history.id,
          contracts: item.contracts.map((contract) => ({
            id: contract.id,
            name: contract.name,
            startDate: contract.startDate,
            endDate: contract.endDate,
            type: contract.type,
            summary: contract.summary,
            ownerId: contract.ownerId,
            //       history: item.history,
            //       image: contract.images,
          })),
        }));
        setInsurancesList(
          insurances.filter((element) => {
            return actualDomicileId == element.domicileId;
          })
        );
      });
    } else {
      console.error("Failed to fetch data:", result.status);
      return;
    }
  };

  return (
    <>
      <UniversalModal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        title="Détails du contrat"
      >
        {seeDetails && (
          <div id="container-contract-display" style={{ width: "100%", display: "flex", justifyContent: "" }}>
            <ContractDisplay
              contract={selectedContract}
            handleDownload={handleDownload}
            closeDisplay={handleCloseModal}
            />
          </div>
        )}
      </UniversalModal>

      <div className="container-global-contrat-import">
        <div className="container-contrat-import">
          <div id="container-display-contracts">
            {insurancesList.length === 0 && (
              <>Pas de contrat pour le moment</>
            )}
            {insurancesList.length > 0 &&
              insurancesList.map((item, index) =>
                item.contracts.map((contract) => (
                  <div className="card-contrat-pres-container" key={index}>
                    <div className="icon-title-contract">
                      <img src="purple-contract.svg" alt="" />
                      <div>{`Nom : ${contract.name}`}</div>
                    </div>
                    <div id="button-see-contract-container">
                      <div
                        id="button-delete-contrat-infos"
                        onClick={() => handleDelete(item.id)}
                      >
                        Supprimer
                      </div>
                      <div
                        id="button-redirect-contrat-infos"
                        onClick={() => showDetails(contract)}
                      >
                        Gérer mon contrat
                      </div>
                    </div>
                  </div>
                ))
              )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ContratImportAndDisplay;
