import React from "react";
import CardMemberTeam from "./CardMemberTeam/CardMemberTeam";
import Carrousel from "./Carousel/Carousel";
import "./CarrouselLanding.css";

const CarrouselLanding = () => {
  return (
    <div id="carrousel-section-landing-container">
      <div id="about-us-title">Qui sommes nous ?</div>
      <div id="about-us-subtitle">Les membres de l'équipe</div>
      <Carrousel
        items={[
          <CardMemberTeam
            name="Clément Monteiro"
            picture={
              <img src="clement-memoji.png" className="img-member-team" />
            }
            job={"Développeur Back & IOS"}
          />,
          <CardMemberTeam
            name="Cyprien Singez"
            picture={
              <img src="cyprien-memoji.png" className="img-member-team" />
            }
            job={"Développeur Back & Android"}
          />,
          <CardMemberTeam
            name="Charly Le Corre"
            picture={
              <img src="charly-memoji.png" className="img-member-team" />
            }
            job={"Développeur IOS"}
          />,
          <CardMemberTeam
            name="Mathis Léchoppier"
            picture={
              <img src="mathis-memoji.png" className="img-member-team" />
            }
            job={"Développeur Back"}
          />,
          <CardMemberTeam
            name="Dylan Laroye"
            picture={<img src="dylan-memoji.png" className="img-member-team" />}
            job={"Développeur Back"}
          />,
          <CardMemberTeam
            name="Thibaud Fontaine"
            picture={
              <img src="thibaud-memoji.png" className="img-member-team" />
            }
            job={"Développeur Web"}
          />,
          <CardMemberTeam
            name="Hugo Deremetz"
            picture={
              <img src="hugoder-memoji.png" className="img-member-team" />
            }
            job={"Développeur Web"}
          />
        ]}
      />
    </div>
  );
};

export default CarrouselLanding;
