import React from "react";
import "./BentoLandingPage.css";

const BentoLandingPage = () => {
  return (
    <div id="wrapper-bento-landing">
      <div className="box-bento-landing b">
        <div className="bento-landing-title">
          Une app disponible sur tous les supports
        </div>
        <div className="bento-landing-text">
          Accédez à toutes les fonctionnalités de Coverage n'importe où, à
          portée de main !
        </div>
        <div id="container-mockup-coverage">
          <img src="mockup-coverage.png" alt="mockup-coverage" />
        </div>
      </div>
      <div className="box-bento-landing c">
        <div className="bento-landing-title">
          Analyse intelligente de factures et contrats d'assurances
        </div>
        <div className="bento-landing-text bento-landing-paragraph">
          <div className="">
            Suivez la valeur de votre patrimoine en temps réel en ajoutant vos
            factures à vos domiciles. Notre IA spécialisée se chargera de
            déduire le montant et le thème de votre facture pour les classer par
            catégories.
          </div>
        </div>
        <div className="bento-landing-text bento-landing-paragraph">
          <div>
            Connectez-vous à votre assureur dans l'application Coverage et
            obtenez rapidement une synthèse de tous vos contrats d'assurance.
            Ainsi, suivez en temps réel la couverture de votre patrimoine en cas
            de sinistre.
          </div>
        </div>
        <div className="">
          <div className="bento-landing-text bento-landing-paragraph">
            Recevez des notifications vous rappelant de stocker vos factures
            pour les restituer à votre assureur en cas de sinistre. Et
            renouvelez vos contrats lorsqu'ils arrivent en fin d'échéance.
          </div>
        </div>
      </div>
      <div className="box-bento-landing d">
        <div className="bento-landing-title">Stocker en toute sécurité</div>
        <div className="bento-landing-text">
          Ajoutez simplement toutes vos factures et vos contrats d'assurances
          pour y accéder à tout moment. Choisissez de les ajouter en ligne pour
          plus de sécurité et les partager avec les membres gestionnaires de vos
          “Domiciles”.
        </div>
      </div>
      <div className="box-bento-landing e">
        <div className="bento-landing-title">Déclaration de sinistre</div>
        <div className="bento-landing-text">
          Vous subissez un sinistre ? Déclarez le directement dans notre
          application pour plus de simplicité. Nous vous accompagneront dans le
          processus et vous pourrez utilisez vos contrats et factures déjà
          importé pour facilter la déclaration de ce dernier.
        </div>
      </div>
      <div className="box-bento-landing a">
        <div id="bento-landing-a-elem-content">
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
              paddingRight: "15px",
            }}
          >
            <div
              style={{
                width: "50%",
                display: "flex",
                justifyContent: "space-between",
                flexDirection: "column",
              }}
            >
              <div>
                <div
                  className="bento-landing-title"
                  style={{ marginBottom: "10px" }}
                >
                  Rejoindre l'accès anticipé
                </div>
                <div id="bento-landing-a-subtitle">
                  Comment devenir un bêta testeur ?
                </div>
                <div className="bento-landing-text">
                  Coverage est une application mobile IOS, Android et un site
                  web d'analyse automatique de contrat d'assurance qui apportera
                  une analyse détaillée.
                </div>
              </div>
              <div
                style={{
                  backgroundColor: "#6750A3",
                  color: "white",
                  padding: "10px",
                  cursor: "pointer",
                  width: "fit-content",
                  borderRadius: "10px",
                  fontSize: "14px",
                  marginTop: "15px",
                }}
                onClick={() => {
                  window.location.href = "/registerBeta";
                }}
              >
                S'inscrire à la bêta
              </div>
            </div>
            <div style={{ width: "50%" }}>
              <img
                src="Asset_beta.png"
                alt=""
                style={{ width: "100%", borderRadius: "10px" }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BentoLandingPage;
