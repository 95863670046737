import React from 'react';
import './QrCode.css';

const QrCode = () => {
  return (
    <div className="qr-code-container">
      <div className="qr-code-wrapper">
        <div className="qr-code-item">
          <img 
            src="/test_qr.png" 
            alt="QR Code 1" 
            className="qr-code-image"
          />
        </div>
        <div className="qr-code-item">
          <img 
            src="/test_qr.png" 
            alt="QR Code 2"
            className="qr-code-image"
          />
        </div>
      </div>
    </div>
  );
};

export default QrCode; 