import React, { useState, useEffect } from "react";
import "./BillsDisplay.css";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import CircleIcon from "@mui/icons-material/Circle";
import DownloadIcon from "@mui/icons-material/Download";
import DeleteIcon from "@mui/icons-material/Delete";

const BillsDisplay = ({ bill, closeBill, listBills }) => {
  const [selectedBill, setSelectedBill] = useState({ ...bill });
  const [initialBill, setInitialBill] = useState(
    JSON.parse(JSON.stringify(bill))
  );
  const [billState, setBillState] = useState({ ...bill });
  const [edit, setEdit] = useState(false);
  const [newProductName, setNewProductName] = useState("");
  const [newProductPrice, setNewProductPrice] = useState("");
  const [newProductQte, setNewProductQte] = useState("");
  const [suggestedTotalPrice, setSuggestedTotalPrice] = useState(
    bill.totalAfterTax
  );
  const [billsList, setBillsList] = useState(listBills);
  const options = [
    "Habitation et Amenagement",
    "Mobilier de jardin",
    "Meubles",
    "Electromenager",
    "Appareils électroniques",
    "Vêtements et accessoires",
    "Bijoux",
    "Œuvres d'art",
    "Voitures",
    "Motos et scooters",
    "Transports légers",
    "Équipements sportifs",
    "Matériel de camping",
    "Instruments de musique",
    "Matériel de photographie",
    "Smartphones",
    "Tablettes",
    "Ordinateurs portables",
    "Lunettes",
    "Équipements médicaux personnels",
  ];
  const typeToSvgMap = {
    "Habitation et Amenagement": "House.svg",
    "Mobilier de jardin": "Barbeuc.svg",
    Meubles: "Sofa.svg",
    Electromenager: "Wash.svg",
    "Appareils électroniques": "Tv.svg",
    "Vêtements et accessoires": "Hanger.svg",
    Bijoux: "Diamond.svg",
    "Œuvres d'art": "Pencil.svg",
    Voitures: "Car.svg",
    "Motos et scooters": "Moto.svg",
    "Transports légers": "Bike.svg",
    "Équipements sportifs": "Football.svg",
    "Matériel de camping": "Tent.svg",
    "Instruments de musique": "Music.svg",
    "Matériel de photographie": "Camera.svg",
    Smartphones: "Phone.svg",
    Tablettes: "Tablet.svg",
    "Ordinateurs portables": "Laptop.svg",
    Lunettes: "Glasses.svg",
    "Équipements médicaux personnels": "Medical.svg",
  };

  useEffect(() => {
    calculateSuggestedTotalPrice();
  }, [billState]);

  const handleTotalPriceChange = (e) => {
    setSuggestedTotalPrice(parseFloat(e.target.value));
    setEdit(true);
  };

  const calculateSuggestedTotalPrice = () => {
    let total = 0;
    billState.products.forEach((product) => {
      total += product.priceAfterTax * (product.quantity || 1);
    });
    setSuggestedTotalPrice(total);
  };

  const handleProductNameChange = (e, index) => {
    const updatedProducts = [...billState.products];
    updatedProducts[index].name = e.target.value;
    setBillState({ ...billState, products: updatedProducts });
    setEdit(true);
  };

  const handleProductPriceChange = (e, index) => {
    const updatedProducts = [...billState.products];
    updatedProducts[index].priceAfterTax = parseFloat(e.target.value);
    setBillState({ ...billState, products: updatedProducts });
    calculateSuggestedTotalPrice();
    setEdit(true);
  };

  const handleProductQuantityChange = (e, index) => {
    const updatedProducts = [...billState.products];
    let quantity = parseFloat(e.target.value);
    if (quantity < 1) quantity = 1;
    updatedProducts[index].quantity = quantity;
    setBillState({ ...billState, products: updatedProducts });
    calculateSuggestedTotalPrice();
    setEdit(true);
  };

  const convertDate = (dateString) => {
    const date = new Date(dateString);

    if (isNaN(date.getTime())) {
      return "Invalid Date";
    }

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");

    return `${day}/${month}/${year}`;
  };

  const handleDeleteProduct = (index) => {
    setBillState((prevBill) => {
      const updatedProducts = [...prevBill.products];
      updatedProducts.splice(index, 1);
      return { ...prevBill, products: updatedProducts };
    });
    calculateSuggestedTotalPrice();
    setEdit(true);
  };

  const handleAddProduct = () => {
    if (
      newProductName.trim() === "" ||
      newProductPrice.trim() === "" ||
      newProductQte.trim() === ""
    ) {
      alert("Veuillez remplir tous les champs.");
      return;
    }
    const newProduct = {
      name: newProductName,
      priceAfterTax: parseFloat(newProductPrice),
      quantity: parseFloat(newProductQte) < 1 ? 1 : parseFloat(newProductQte),
      currency: billState.currency,
    };
    let tampPrice = parseFloat(newProductPrice) * newProduct.quantity;
    setSuggestedTotalPrice(suggestedTotalPrice + tampPrice);
    setBillState((prevBill) => ({
      ...prevBill,
      products: [...prevBill.products, newProduct],
    }));
    setNewProductName("");
    setNewProductQte("");
    setNewProductPrice("");
    setEdit(true);
  };

  const cancelButton = () => {
    setEdit(false);
    setBillState({ ...initialBill });
    setSuggestedTotalPrice(initialBill.totalAfterTax);
  };

  const handleApplyChanges = async () => {
    console.log(billState);
    let result = await fetch(
      `https://api.coverage-eip.com/bills/${billState.id}`,
      {
        method: "PUT",
        body: JSON.stringify({
          domicileId: billState.domicileId,
          billNumber: billState.billNumber,
          billDate: billState.billDate,
          totalAfterTax: suggestedTotalPrice,
          currency: billState.currency,
          products: billState.products,
          tags: billState.tags,
          storeName: billState.storeName,
          type: billState.type,
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }
    );
    if (result.status === 200) {
      result = result.json().then((data) => {
        setEdit(false);
        setInitialBill(data);
        setBillState(data);
        updateBillsList(data);
      });
    } else {
      alert("Echec de la modification de la facture");
    }
  };

  const handleBillClick = (billItem) => {
    console.log(billItem);
    setSelectedBill(billItem);
    setInitialBill(JSON.parse(JSON.stringify(billItem)));
    setBillState({ ...billItem });
    setEdit(false);
  };

  const updateBillsList = (updatedBill) => {
    const updatedBillsList = billsList.map((billItem) =>
      billItem.id === updatedBill.id ? updatedBill : billItem
    );
    setBillsList(updatedBillsList);
  };

  const handleDeleteBill = async () => {
    const result = await fetch(
      `https://api.coverage-eip.com/bills/${billState.id}`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }
    );

    if (result.status === 200) {
      const updatedBillsList = billsList.filter(
        (billItem) => billItem.id !== billState.id
      );
      setBillsList(updatedBillsList);

      if (updatedBillsList.length > 0) {
        handleBillClick(updatedBillsList[0]);
      } else {
        closeBill();
      }
    } else {
      alert("Echec de la suppression de la facture");
    }
  };

  const handleTypeChange = (e) => {
    let tamp = billState.tags;
    tamp[0] = e.target.value;
    setBillState({ ...billState, tags: tamp });
    setEdit(true);
  };

  const BillTypeIcon = ({ type }) => {
    const svgFileName = typeToSvgMap[type] || "receip.svg";
    return (
      <img
        src={`/TypesBills/${svgFileName}`}
        alt={type}
        className="bill-type-icon"
        style={{
          width: "90%",
          height: "90%",
          objectFit: "cover",
          objectPosition: "center",
        }}
      />
    );
  };

  return (
    <>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div id="title-dashboard" style={{ marginTop: "3em" }}>
          {" "}
          <ReceiptLongIcon style={{ marginRight: "10px" }} /> Factures
        </div>
        <div className="container-display-bill-infos">
          <div className="bills-list">
            {billsList.map((billItem, index) => (
              <div
                key={index}
                className={`bill-item ${billItem.id === selectedBill.id ? 'bill-selected' : ''}`}
                onClick={() => handleBillClick(billItem)}
              >
                <div className="bill-icon-container">
                  <BillTypeIcon type={billItem.tags[0]} />
                </div>
                <div className="bill-info">
                  <div className="bill-date">
                    <span>Date d'achat : </span>
                    {convertDate(billItem.billDate)}
                  </div>
                  <div className="bill-store">
                    <span>Enseigne : </span>
                    {billItem.storeName}
                  </div>
                </div>
              </div>
            ))}
          </div>

          <div className="vertical-separator"></div>

          <div className="display-bill-infos">
            <div className="title-section-bills-details">
              Facture - {billState.storeName}
            </div>
            
            <div className="bill-info-header" style={{ fontSize: "1rem" }}>
              <div id="buy-date-bill-display">
                <span>Date d'achat : </span>
                {convertDate(billState.billDate)}
              </div>
              
              <div className="bill-tags" style={{ fontSize: "1rem" }}>
                <span>Type : </span>
                <select
                  value={billState.tags[0] || ""}
                  onChange={handleTypeChange}
                  className="input-edit-infos-bill"
                >
                  <option value="">Sélectionner un type</option>
                  {options.map((option, index) => (
                    <option key={index} value={option}>{option}</option>
                  ))}
                </select>
              </div>
            </div>

            <div className="bill-content-wrapper">
              <div id="container-product-list-bill-display">
                <span id="title-list-product-bill-display">
                  Liste des articles :
                </span>
                {billState.products.map((product, index) => (
                  <div
                    key={index}
                    className="line-product-bill-display"
                    style={{ color: "#5e63b6" }}
                  >
                    <CircleIcon
                      style={{ color: "#5E63B6", marginRight: "15px" }}
                    />
                    <span>Nom :</span>
                    <input
                      type="text"
                      value={product.name}
                      onChange={(e) => handleProductNameChange(e, index)}
                      onFocus={() => setEdit(true)}
                      className="input-edit-infos-bill input-edit-infos-bill-name"
                    />
                    <span>Prix :</span>
                    <input
                      type="number"
                      value={product.priceAfterTax}
                      onChange={(e) => handleProductPriceChange(e, index)}
                      onFocus={() => setEdit(true)}
                      className="input-edit-infos-bill input-edit-infos-bill-price"
                    />
                    <span>Quantité :</span>
                    <input
                      type="number"
                      value={product.quantity || 1}
                      onChange={(e) => handleProductQuantityChange(e, index)}
                      onFocus={() => setEdit(true)}
                      className="input-edit-infos-bill input-edit-infos-bill-qte"
                      min="1"
                    />
                    {edit && (
                      <img
                        src="trash.svg"
                        onClick={() => handleDeleteProduct(index)}
                        alt="delete"
                      />
                    )}
                  </div>
                ))}
                {edit && (
                  <div id="container-add-product">
                    <CircleIcon
                      style={{ color: "#5E63B6", marginRight: "15px" }}
                    />
                    <span style={{ color: "#5e63b6" }}>Nom :</span>
                    <input
                      type="text"
                      placeholder="Nom du produit"
                      value={newProductName}
                      onChange={(e) => setNewProductName(e.target.value)}
                      className="input-edit-infos-bill input-edit-infos-bill-name"
                    />
                    <span>Prix :</span>
                    <input
                      type="number"
                      placeholder="Prix du produit"
                      value={newProductPrice}
                      onChange={(e) => setNewProductPrice(e.target.value)}
                      className="input-edit-infos-bill input-edit-infos-bill-price"
                    />
                    <span>Quantité :</span>
                    <input
                      type="number"
                      placeholder="Quantité"
                      value={newProductQte}
                      onChange={(e) => setNewProductQte(e.target.value)}
                      className="input-edit-infos-bill input-edit-infos-bill-qte"
                      min="1"
                    />
                    <span
                      onClick={handleAddProduct}
                      id="add-product-button-display-bill"
                    >
                      +
                    </span>
                  </div>
                )}
              </div>

              <div className="bill-footer">
                <div id="total-bill" style={{ color: "#5e63b6" }}>
                  Total :{" "}
                  <input
                    type="number"
                    className="input-edit-infos-bill input-edit-infos-bill-totalprice"
                    value={suggestedTotalPrice}
                    onChange={handleTotalPriceChange}
                    readOnly
                  />
                  {billState.currency}
                </div>
                <div className="buttons-display-bill-container">
                  {edit && (
                    <>
                      <div
                        className="button-cancel-bills"
                        onClick={cancelButton}
                      >
                        Annuler
                      </div>
                      <div
                        className="button-apply-bills"
                        onClick={handleApplyChanges}
                      >
                        Valider
                      </div>
                    </>
                  )}
                  {!edit && (
                    <>
                      <div className="button-cancel-bills" onClick={closeBill}>
                        Fermer
                      </div>
                      <div
                        className="button-delete-bills"
                        onClick={handleDeleteBill}
                      >
                        Supprimer
                        <DeleteIcon style={{ marginLeft: "10px" }} />
                      </div>
                      <div
                        className="button-apply-bills"
                        onClick={() => {
                          window.open(billState.images[0].url, "_blank");
                        }}
                      >
                        Télécharger la facture{" "}
                        <DownloadIcon style={{ marginLeft: "10px" }} />
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BillsDisplay;
