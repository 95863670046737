import React from "react";
import "./Contract.css";
import { useState, useEffect, useRef } from "react";
import ContractDisplay from "../../component/ContractDisplay/ContractDisplay";
import LoadingPopup from "../../component/LoadingPopup/LoadingPopup";
import UniversalModal from "../../component/UniversalModal/UniversalModal";
import ConnectInsurancesPopup from "../../component/ConnectInsurancesPopup/ConnectInsurancesPopup";
import { set } from "date-fns";
const Contract = () => {
  const [insurancesList, setInsurancesList] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [contractType, setContractType] = useState("");
  const [contractName, setContractName] = useState("");
  const [insuranceName, setInsuranceName] = useState("");
  const [insuranceSummary, setInsuranceSummary] = useState("");
  const [domicileName, setDomicileName] = useState("");
  const [Locaux, setLocaux] = React.useState([]);
  const [selectedContract, setSelectedContract] = useState(null);
  const [insuranceToUpload, setInsuranceToUpload] = useState(null);
  var insuranceToAnalyze = "";
  const [seeDetails, setSeeDetails] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalImportOpen, setIsModalImportOpen] = useState(false);
  const [insurancesFromAssurance, setInsurancesFromAssurance] = useState([]);
  const [isFieldEmpty, setIsFieldEmpty] = useState(false);
  const isFormValid = insuranceName && contractType && contractName;

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!insuranceName || !contractType || !contractName) {
      setIsFieldEmpty(true);
    } else {
      setIsFieldEmpty(false);
      addInsurances();
    }
  };

  const getLocaux = async () => {
    let result = await fetch("https://api.coverage-eip.com/domicile", {
      method: "GET",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });

    result.json().then((data) => {
      const Locaux = data.map((item) => ({
        id: item.id,
        domicileName: item.domicileName,
      }));
      setLocaux(Locaux);
      console.log(Locaux);
    });
  };

  const putInsurances = async (historyId, contractId) => {
    const temp = Locaux.find(
      (element) => element.domicileName === domicileName
    );
    console.log(temp);

    let payload = {
      historyId: historyId,
      contractId: contractId,
    };

    if (temp) {
      payload.domicileId = temp.id;
      console.log(temp.id);
    }

    let result = await fetch("https://api.coverage-eip.com/contracts", {
      method: "Put",
      body: JSON.stringify(payload),
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });

    if (result.status === 200) {
      alert("Contrat importé avec succès");
      window.location.reload();
    } else {
      alert(
        "Erreur lors de l'ajout au domicile. Le contrat a bien été importé mais il n'est pas relié à un domicile."
      );
    }
  };

  const addInsurances = async () => {
    console.log(insuranceSummary);
    let result = await fetch("https://api.coverage-eip.com/contracts", {
      method: "Post",
      body: JSON.stringify({
        startDate: startDate,
        type: contractType,
        name: contractName,
        insuranceName: insuranceName,
        data: insuranceToUpload,
        endDate: endDate,
        summary: insuranceSummary,
      }),
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });
    if (result.status === 200) {
      result = result.json().then((data) => {
        console.log(data);
        console.log(data.id);
        console.log(data.contracts[0].id);
        putInsurances(data.id, data.contracts[0].id);
      });
    } else {
      alert("erreur lors de l'importation du contrat");
    }
  };

  const getInsurances = async () => {
    let result = await fetch(
      "https://api.coverage-eip.com/contracts/allContracts",
      {
        method: "get",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }
    );

    if (result.status === 200) {
      console.log(result);
      result.json().then((data) => {
        const insurances = data.map((item) => ({
          id: item.id,
          domicileId: item.domicileId,
          contracts: item.contracts.map((contract) => ({
            id: contract.id,
            name: contract.name,
            startDate: contract.startDate,
            endDate: contract.endDate,
            type: contract.type,
            summary: contract.summary,
            ownerId: contract.ownerId,
            url: contract.image.url,
          })),
        }));
        console.log(data);
        setInsurancesList(insurances);
      });
    } else {
      console.error("Failed to fetch data:", result.status);
    }
    let result2 = await fetch("https://mono.coverage-eip.com/policies", {
      method: "GET",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
    });
    let datas = await result2.json();
    if (result2.status === 200) {
      console.log(datas);
      setInsurancesFromAssurance(datas);
    } else {
      console.error("Failed to fetch data:", result2.status);
    }
  };

  useEffect(() => {
    getInsurances();
    getLocaux();
  }, []);

  const analyzeInsurances = async () => {
    setLoading(true);
    let result = await fetch(
      "https://api.coverage-eip.com/insurances/analyze",
      {
        method: "post",
        body: JSON.stringify({
          data: insuranceToAnalyze,
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }
    );
    if (result.status === 200) {
      result = result.json().then((data) => {
        if (data.startDate) setStartDate(data.startDate);
        if (data.endDate) setEndDate(data.endDate);
        if (data.insuranceName) setContractName(data.insuranceName);
        if (data.type) setContractType(data.type);
        if (data.summary) setInsuranceSummary(data.summary);
        setLoading(false);
        setIsModalImportOpen(true);
      });
    }
    setLoading(false);
    return;
  };

  const chooseFileToAnalyze = async (event) => {
    const file = event.target.files[0];
    if (file && file.type === "application/pdf") {
      const base64 = await convertToBase64(file);
      setInsuranceToUpload(base64);
      insuranceToAnalyze = base64;
      analyzeInsurances();
    } else {
      alert("Veuillez sélectionner un fichier PDF.");
    }
  };

  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const base64Data = reader.result.split(",")[1];
        resolve(base64Data);
      };
      reader.onerror = (error) => reject(error);
    });
  };

  const handleDelete = (id) => {
    const confirmDelete = window.confirm(
      "Voulez-vous vraiment supprimer ce contrat?"
    );
    if (confirmDelete) {
      deleteInsurance(id);
    }
  };

  const deleteInsurance = async (id) => {
    const response = await fetch(
      `https://api.coverage-eip.com/contracts/full/${id}`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );

    if (response.ok) {
      alert("Contrat supprimé avec succès.");
      window.location.reload();
    } else {
      alert(`Échec de la suppression du contrat : ${response.status}`);
    }
  };

  const showDetails = (contract) => {
    setSelectedContract(contract);
    setSeeDetails(true);
  };

  const handleDownload = (url) => {
    if (url) {
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "true");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      alert("URL non spécifiée pour le téléchargement.");
    }
  };

  return (
    <>
      {loading && <LoadingPopup />}
      {seeDetails && (
        <ContractDisplay
          contract={selectedContract}
          handleDownload={handleDownload}
          closeDisplay={() => setSeeDetails(false)}
        />
      )}
      {!seeDetails && (
        <div className="container-page">
          <div id="title-dashboard">
            {" "}
            <img
              className=""
              src="white-contrat.svg"
              alt="Icon Dashboard"
            />{" "}
            Contrats
          </div>
          <div id="container-upload-display-contracts">
            <div id="container-upload-contracts">
              <input
                type="file"
                onChange={chooseFileToAnalyze}
                accept="application/pdf"
                style={{ display: "none" }}
                id="fileInput"
              />
              <div
                onClick={() => document.getElementById("fileInput").click()}
                id="upload-contract-data"
              >
                Ajouter un contrat PDF
              </div>
              {insuranceSummary && (
                <div>
                  Remplissez les informations ci dessous pour ajouter votre
                  contrat
                </div>
              )}
              {/* {!insuranceSummary && <div>Veuillez importer votre contract</div>} */}
              <UniversalModal
                isOpen={isModalImportOpen}
                onClose={() => {
                  setIsModalImportOpen(false);
                }}
              >
                <div id="container-form-popup">
                  <div className="form-edit-date-infos-contract">
                    <form onSubmit={handleSubmit} id="form-confirm-import">
                      <div>
                        <label>
                          Date de début du contrat:
                          <input
                            className="input-edit-infos-contract"
                            type="date"
                            value={startDate}
                            onChange={(e) => setStartDate(e.target.value)}
                            readOnly={!insuranceSummary}
                          />
                        </label>
                      </div>
                      <div>
                        <label>
                          Date de fin du contrat:
                          <input
                            className="input-edit-infos-contract"
                            type="date"
                            value={endDate}
                            onChange={(e) => setEndDate(e.target.value)}
                            readOnly={!insuranceSummary}
                          />
                        </label>
                      </div>
                      <div>
                        <label>
                          Nom de l'assureur :
                          <input
                            className={`input-edit-infos-contract ${
                              isFieldEmpty && !contractName ? "input-error" : ""
                            }`}
                            type="text"
                            value={insuranceName}
                            onChange={(e) => setInsuranceName(e.target.value)}
                            readOnly={!insuranceSummary}
                          />
                        </label>
                      </div>
                      <div>
                        <label>
                          Type de contrat:
                          <input
                            className={`input-edit-infos-contract ${
                              isFieldEmpty && !contractName ? "input-error" : ""
                            }`}
                            type="text"
                            value={contractType}
                            onChange={(e) => setContractType(e.target.value)}
                            readOnly={!insuranceSummary}
                          />
                        </label>
                      </div>
                      <div>
                        <label>
                          Nom du contrat:
                          <input
                            className={`input-edit-infos-contract ${
                              isFieldEmpty && !contractName ? "input-error" : ""
                            }`}
                            type="text"
                            value={contractName}
                            onChange={(e) => setContractName(e.target.value)}
                            readOnly={!insuranceSummary}
                          />
                        </label>
                      </div>
                      <div>
                        <label>
                          Domicile du contrat:
                          <input
                            className="input-edit-infos-contract"
                            type="text"
                            value={domicileName}
                            onChange={(e) => setDomicileName(e.target.value)}
                            readOnly={!insuranceSummary}
                          />
                        </label>
                      </div>
                      <div id="container-send-data-contract-edit">
                        <button
                          type="submit"
                          id="send-data-contract-edit"
                          disabled={!isFormValid}
                        >
                          Soumettre
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </UniversalModal>
              <div className="insurance-connection-section">
                <div className="insurance-text">
                  Vous possèder un compte chez une assurance et souhaitez récuper vos contrats ?
                </div>
                <div 
                  className="connect-insurance-button"
                  onClick={() => setIsModalOpen(true)}
                >
                  Connectez vous à votre assurance
                </div>
              </div>
              <UniversalModal
                isOpen={isModalOpen}
                onClose={() => setIsModalOpen(false)}
              >
                <ConnectInsurancesPopup onClose={() => setIsModalOpen(false)} />
              </UniversalModal>
            </div>
            <div id="container-display-contracts">
              <div id="inside-container-display-contracts">
                <div
                  className="part-inside-container-display-contracts"
                  id="picdc1"
                >
                  {insurancesList.length === 0 && (
                    <>Pas de contrat pour le moment</>
                  )}
                  {insurancesList.length > 0 &&
                    insurancesList.map((item, index) =>
                      item.contracts.map((contract) => (
                        <div
                          className="card-contrat-pres-container"
                          key={index}
                        >
                          <div className="icon-title-contract">
                            <img src="purple-contract.svg" alt="" />
                            <div>{`${contract.name}`}</div>
                          </div>
                          <div id="button-see-contract-container">
                            <div
                              id="button-delete-contrat-infos"
                              onClick={() => handleDelete(item.id)}
                            >
                              Supprimer
                            </div>
                            <div
                              id="button-redirect-contrat-infos"
                              onClick={() => showDetails(contract)}
                            >
                              Gérer mon contrat
                            </div>
                          </div>
                        </div>
                      ))
                    )}
                </div>
                <div
                  className="part-inside-container-display-contracts"
                  id="picdc2"
                >
                  <div style={{ fontSize: "20px", marginBottom: "20px" }}> Contrats importés d'assureur :</div>
                  <div id="scroll-list-imported-insurances">
                    {insurancesFromAssurance.map((item, index) => (
                      <>
                        {item.type === "Car" && (
                          <div className="contract-card" key={index}>
                            <div className="contract-info">
                              <div className="contract-details">
                                <div className="vehicle-model">{item.vehicleModel}</div>
                                <div className="formula">{item.formula}</div>
                              </div>
                            </div>
                            <div className="price-container">
                              <div className="price">{item.yearlyContribution} €</div>
                            </div>
                          </div>
                        )}
                      </>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Contract;
