import React, { useEffect } from "react";
import "./Dashboard.css";
import CardContact from "./Components/CardContact";
import CardNotif from "./Components/CardNotif";
import CardLocal from "./Components/CardLocal";
import CardAssurance from "./Components/CardAssurance";

const Dashboard = () => {
  const [locaux, setLocaux] = React.useState(null);
  const [collabs, setCollaborators] = React.useState(null);
  const [assurances, setAssurances] = React.useState(null);
  const [notifs, setNotifs] = React.useState(null);
  const [htmlContent, setHtmlContent] = React.useState([]);

  useEffect(() => {
    getHtmlContent();
    getLocaux();
    getListContacts();
    getNotifs();
  }, []);

  const getHtmlContent = async () => {
    // let result = await fetch("https://threadsnews.api.coverage-eip.com/thread", {
    //   method: "GET",
    //   headers: {
    //     "Content-Type": "application/json; charset=utf-8",
    //     Authorization: "Bearer " + localStorage.getItem("token"),
    //   },
    // });
    // if (result.status !== 200) return;
    // let data = await result.json();
    
    // // Filtrer les news cachées
    // const hiddenNews = JSON.parse(localStorage.getItem("hiddenNews") || "[]");
    // const visibleNews = data.filter(content => !hiddenNews.includes(content.id));
    
    // setHtmlContent(visibleNews);
  };

  const hideNews = (id) => {
    // Ajouter l'ID de la news cachée dans le localStorage
    const hiddenNews = JSON.parse(localStorage.getItem("hiddenNews") || "[]");
    hiddenNews.push(id);
    localStorage.setItem("hiddenNews", JSON.stringify(hiddenNews));

    // Mettre à jour le state pour cacher immédiatement la news
    setHtmlContent(htmlContent.filter(content => content.id !== id));
  };

  const getLocaux = async () => {
    let result = await fetch("https://api.coverage-eip.com/domicile", {
      method: "GET",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });
    result = await result.json();
    setLocaux(result);
  };

  const getListContacts = async () => {
    let result = await fetch("https://api.coverage-eip.com/contacts", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });
    if (result.status === 200) {
      result = result.json().then((data) => {
        setCollaborators(data);
      });
    }
  };

  const getListContactsRequest = async () => {
    let result = await fetch(
      "https://api.coverage-eip.com/contactRequest/contact",
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }
    );
    return result.json();
  };

  const getLocalRequests = async () => {
    let result = await fetch(
      "https://api.coverage-eip.com/domicileRequest/contact",
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }
    );
    result = await result.json();
    return result;
  };

  const getNotifs = async () => {
    let collabsRequest = await getListContactsRequest();
    let localRequests = await getLocalRequests();
    let tampNotifs = [];
    console.log(localRequests);
    if (!localRequests) return;
    for (let i = 0; i < localRequests.length; i++)
      tampNotifs.push({
        type: "local",
        name:
          localRequests[i].userId.firstName +
          " " +
          localRequests[i].userId.lastName,
        date: localRequests[i].date,
      });
    if (!collabsRequest) return;
    for (let i = 0; i < collabsRequest.length; i++)
      tampNotifs.push({
        type: "contact",
        name:
          collabsRequest[i].userId.firstName +
          " " +
          collabsRequest[i].userId.lastName,
        date: collabsRequest[i].date,
      });
    tampNotifs.sort((a, b) => {
      return new Date(b.date) - new Date(a.date);
    });
    setNotifs(tampNotifs);
  };

  return (
    <div className="container-page">
      <div id="title-dashboard">
        <img src="dashboard.svg" alt="Icon Dashboard" />
        Dashboard
      </div>
      
      <div id="container-bento-dashboard">
        {/* Fils d'actualité */}
        <div className="bento-dashboard-card">
          <div className="inside-bento-dashboard">
            <span>
              <img src="contrat.svg" alt="contrat-icon" />
              Fils d'actualité
            </span>
            <div>
              {htmlContent && htmlContent.map((content) => (
                <div key={content.id} className="news-item">
                  <button 
                    className="hide-button"
                    onClick={() => hideNews(content.id)}
                  >
                    Masquer
                  </button>
                  <div dangerouslySetInnerHTML={{ __html: content.html }} />
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* Mes locaux */}
        <div className="bento-dashboard-card">
          <div className="inside-bento-dashboard">
            <span>
              <img src="building.svg" alt="building-icon" />
              Mes locaux
            </span>
            <div className="locaux-slider-dashboard">
              {locaux &&
                Array.isArray(locaux) &&
                locaux.map((local) => (
                  <CardLocal
                    localName={local.domicileName}
                    localPic={local.photo?.url}
                    localId={local.id}
                    key={local.id}
                  />
                ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
