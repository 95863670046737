import React, { useEffect, useState } from "react";
import './ConnectInsurancesPopup.css';

const ConnectInsurancesPopup = ({ onClose }) => {
  const [selectedInsurance, setSelectedInsurance] = useState(null);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [responseMessage, setResponseMessage] = useState("");
  const directAssurance = "67377b1bb2a2c005d89aaee6";
  const eurofil = "67377b1bb2a2c005d89aaee8";

  const handleInsuranceSelect = (insurance) => {
    setSelectedInsurance(insurance);
    setResponseMessage("");
  };

  useEffect(() => {
    const getInsurance = async () => {
      let result = await fetch(
        "https://mono.coverage-eip.com/policies", {
          method: "GET",
          headers: {
            "Authorization": "Bearer " + localStorage.getItem("token"),
          "Content-Type": "application/json",
          },
        }
      );
      result = await result.json();
      console.log(result);
    };
    getInsurance();
  }, []);

  const handleLogin = async () => {
    let assuranceId = selectedInsurance === "Direct Assurance" ? directAssurance : eurofil;

    let result = await fetch(
      "https://mono.coverage-eip.com/insurances/" + assuranceId + "/auth", {
        method: "POST",
        body: JSON.stringify({
          id: email,
          password: password,
        }),
        headers: {
          "Authorization": "Bearer " + localStorage.getItem("token"),
          "Content-Type": "application/json",
        },
      }
    );
    if (result.status < 300) {
      console.log(result);
      window.location.reload();
    } else {
      setResponseMessage(result.message);
    }
  };

  return (
    <div className="insurance-popup-container-wrapper">
    <div className="insurance-popup-container">
      <img
        className="cross-popup"
        src="cross.svg"
        alt="Icon cross"
        onClick={onClose}
      />
      
      <div>
        <div className="popup-title">
          Connexion à votre assurance
        </div>
        
        <div className="popup-subtitle">
          Connectez vous à votre assurance pour récupérer vos contrats.
        </div>
        
        <div className="insurances-grid">
          <div 
            className={`insurance-logo-container ${
              selectedInsurance === "Direct Assurance" ? "selected-insurance" : ""
            }`}
            onClick={() => handleInsuranceSelect("Direct Assurance")}
          >
            <img
              src="Insurances/Direct_Assurance_logo.png"
              alt="Direct Assurance"
            />
          </div>
          
          <div 
            className={`insurance-logo-container ${
              selectedInsurance === "Eurofil" ? "selected-insurance" : ""
            }`}
            onClick={() => handleInsuranceSelect("Eurofil")}
          >
            <img
              src="Insurances/logo_eurofil.jpg"
              alt="Eurofil"
            />
          </div>
        </div>
        
        {selectedInsurance && (
          <div className="login-form-container">
            <div className="input-container">
              <input
                type="text"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="login-input"
              />
            </div>
            
            <div className="input-container">
              <input
                type="password"
                placeholder="Mot de passe"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="login-input"
              />
            </div>
            
            <div style={{ textAlign: 'center' }}>
              <button
                onClick={handleLogin}
                className="login-button"
              >
                Se connecter
              </button>
            </div>
          </div>
        )}
        
        {responseMessage && (
          <div className={`response-message ${
            responseMessage.includes("réussie") ? "success-message" : "error-message"
          }`}>
            {responseMessage}
          </div>
        )}
      </div>
    </div>
    </div>
  );
};

export default ConnectInsurancesPopup;